.user {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.appBar {
  height: 80px;
  background-color: #046bb5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}

.navIcon {
  width: 75px;
  height: 75px;
}