.resultPage {
  margin-top: 100px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linearProgress {
  width: 100%;
}

.resultContent {
  margin-top: 50px;
  width: 50%;
}

.resultData {
  display: flex;
  justify-content: space-between;
}

.boldData {
  font-weight: bold;
}

.resDescription {
  margin-top: 20px;
}

.btnGroup {
  margin-top: 20px;
  display: flex;
  width: 30%;
  justify-content: center;
}

.submitButton {
  border-color: black !important;
  color: black !important;
}

.submitButton:hover {
  border-color: #046bb5 !important;
  background-color: #046bb5 !important;
  color: white !important;
}