.greeting {
  width: 80%;
  margin-top: 100px;
  display: inline-flex;
}

.testDescription {
  width: 50%;
  margin-top: 80px;
}

.pageTitle {
  font-size: 60px;
  font-weight: bold;
}

.ad {
  font-size: 32px;
  color: rgb(116, 116, 116);
}

.description {
  margin-top: 20px;
  color: rgb(116, 116, 116);
}

.link {
  text-decoration: none;
  color: #046bb5;
}

.testButton {
  margin-top: 40px !important;
  height: 70px;
  border-radius: 15px !important;
  font-family: 'Inter' !important;
  padding: 0px 20px !important;
  font-size: 30px !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  background-color: #046bb5 !important;
}

.mainBackground {
  width: 750px;
  height: 540px;
}