.userResultPage {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.userInfo {
  margin-top: 7%;
  width: 20%;
}

.userData {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.imgRes {
  width: 30%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}

.imgTitle {
  width: 100%;
  margin-bottom: 20px;
  display: inline-flex;
  justify-content: space-between;
}