.instructionPage {
  width: 80%;
  margin-top: 5%;
  display: inline-flex;
  align-items: center;
}

.description {
  width: 30%;
  margin-right: 5%;
}

.descriptionPart {
  text-indent: 30px;
  font-size: 18px;
}

.imgAndBtn {
  width: 65%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.nextBtn {
  height: 40px;
  border-color: black !important;
  color: black !important;
}

.nextBtn:hover {
  border-color: #046bb5 !important;
  background-color: #046bb5 !important;
  color: white !important;
}

@media (max-width: 1300px) {
  .instructionPage {
    width: 95%;
  }

  .description {
    width: 40%;
  }

  .img {
    width: 80%;
  }

  .imgAndBtn {
    width: 60%;
  }
}