.logPage {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logBlock {
  margin-top: 80px;
  width: 100%;
}

.logTitle {
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 60px;
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.errorMessage {
  font-size: 18px;
  color: red;
  width: 70%;
}

.buttonGroup {
  width: 70%;
}

.loginButton {
  margin-right: 40px !important;
  font-weight: bold !important;
  background-color: #046bb5 !important;
}

.cancelButton {
  color: black !important;
  border-color: black !important;
}

.alreadyReg {
  margin-top: 100px;
}

.regLink {
  color: #046bb5;
  text-decoration: none;
}

.dialogTitle {
  font-weight: bold;
}