.appBar {
  height: 80px;
  background-color: #046bb5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}

.navIcon {
  width: 75px;
  height: 75px;
}

.navLinks {
  display: inline-flex;
}

.link {
  margin-left: 40px;
  text-decoration: none;
  color: white;
  font-size: 18px;
  padding: 5px 10px;
  border: 1px solid #046bb5;
  transition: all .3s ease;
}

.link:hover {
  border: 1px solid #250974;
  border-radius: 30px;
}