.testPage {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.expand {
  display: none;
}

.palette {
  width: 60%;
  margin-top: 40px;
  display: inline-flex;
  justify-content: space-around;
}

.colors {
  display: inline-flex;
  align-items: flex-start;
}

.singleColor {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-bottom: 5px;
  border: 2px solid black;
  border-radius: 5px;
}

.singleColor:hover {
  cursor: pointer;
}

.otherButtons {
  display: flex;
  flex-direction: column;
}

.sendButton:hover {
  border-color: #046bb5 !important;
  background-color: #046bb5 !important;
  color: white !important;
}

.testResult {
  padding: 10px;
  width: 350px;
  display: flex;
  flex-direction: column;
}

.dialogTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.resultData {
  display: flex;
  justify-content: space-between;
}

.boldData {
  font-weight: bold;
}

.dialogBtnGroup {
  width: 100%;
  margin-top: 10px;
  display: inline-flex;
  justify-content: flex-end;
}

@media (max-width: 1300px) {
  .palette {
    width: 80%;
  }
}