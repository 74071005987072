.adminLoginPage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logBlock {
  margin-top: 10%;
}

.title {
  font-size: 40px;
  font-weight: 800;
}

.inputBlock {
  display: flex;
  flex-direction: column;
}

.input {
  margin-top: 20px !important;
}

.errorMessage {
  margin-top: 20px;
  font-size: 18px;
  color: red;
}

.loginButton {
  margin-right: 40px !important;
  font-weight: bold !important;
  background-color: #046bb5 !important;
  width: 100%;
  margin-top: 20px !important;
}