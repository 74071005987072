@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-style: normal;
}

/* Убрать скролбар в хроме */
body::-webkit-scrollbar {
  width: 0;
}

body::-webkit-scrollbar- {
  width: 0;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Убрать скролбар в ie */
  -ms-overflow-style: none;
  overflow-x: hidden;

  height: 100%;

  font-family: 'Inter';
}

/* Для таблиц */
.MuiTableHead-root th {
  font-size: 12px;
  padding: 10px 6px;
}

.MuiTableBody-root td {
  font-size: 12px;
  padding: 10px 6px;
}

.MuiTablePagination-root p {
  font-family: 'Noto Sans', sans-serif !important;
}

.MuiTablePagination-selectLabel {
  visibility: hidden;
}

.MuiTablePagination-selectLabel:after {
  content: 'Строк на странице';
  font-family: 'Noto Sans', sans-serif !important;
  visibility: visible;
  display: block;
  position: absolute;
  top: 15px;
  right: 260px;
}

.MuiTableCell-root {
  font-family: 'Noto Sans', sans-serif !important;
}


/* Анимация для карточек направлений */
/* .cards-enter {
}

.cards-enter-active {
}

.cards-exit {
} */

.cards-exit-active {
  opacity: 0;
  transition: all .7s ease-out;
}