.regPage {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.regBlock {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.regTitle {
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 60px;
}

.regWays {
  width: 100%;
  display: inline-flex;
}

.regFields {
  width: 40%;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.inputFields {
  margin-top: 40px;
}

.asterick {
  color: red;
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
}

.ages {
  height: 200px;
}

.errorMessage {
  font-size: 18px;
  color: red;
  width: 100%;
}

.buttonGroup {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.regButton {
  margin-right: 40px !important;
  font-weight: bold !important;
  background-color: #046bb5 !important;
}

.regButton:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.divider {
  margin: 0 10% !important;
  height: 100%;
}

.logWith {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icons {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.yandexButton {
  width: 200px;
  height: 50px;
  background-color: #fc3f1d !important;
  border-radius: 6px !important;
  color: white !important;
  font-size: 20px !important;
  text-transform: none !important;
  margin-bottom: 20px !important;
  opacity: 0.7;
}

.vkButton {
  width: 200px;
  height: 50px;
  background-color: #5281b8 !important;
  border-radius: 6px !important;
  color: white !important;
  font-size: 20px !important;
  text-transform: none !important;
  opacity: 0.7;
}

.alreadyReg {
  margin-top: 100px;
}

.loginLink {
  color: #046bb5;
  text-decoration: none;
}

.dialogText {
  padding: 0 24px 16px 24px;
}

.dialogButtons {
  padding-bottom: 10px;
  padding-left: 20px;
}

@media (max-width: 1300px) {
  .regPage {
    width: 70%;
  }
}