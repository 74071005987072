.users {
  margin: 2% 20px;
}

.pageTitle {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
}


.searchParams {
  display: inline-flex;
  width: 40%;
  margin-bottom: 20px;
}

.tableSearch {
  background-color: white;
  border-radius: 15px;
  width: 80%;
}

.tableSearch div {
  border-radius: 15px;
}

.filterButton {
  margin-left: 10px !important;
  background-color: #fff !important;
  border-radius: 15px !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  width: 56px;
  color: #046bb5 !important;
}

.filterButton:hover {
  border-color: black !important;
}

.table {
  width: 100%;
  border: 1px solid rgba(224, 224, 224, 1) !important;
}

.personName {
  color: #046bb5 !important;
}

.editUser {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 520px;
  height: 400px;
  background-color: white;
  border: 2px solid #000;
  border-radius: 6px;
  box-shadow: 24;
  padding: 30px;
}

.filterModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 200px;
  background-color: white;
  border: 2px solid #000;
  border-radius: 6px;
  box-shadow: 24;
  padding: 10px 30px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.modalFilterButton {
  color: #046bb5 !important;
}

.addNewUserBtn {
  color: #046bb5 !important;
}

.addNewUserBtn {
  color: green;
}

.deleteIcon {
  color: red;
}

.newUserDialogContent {
  width: 350px;
}

.newUserInput {
  margin-top: 10px !important;
}

.deleteDialogTitle {
  font-size: 20px;
  padding: 20px 20px 10px 20px;
}

.deleteDialogBtns {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}